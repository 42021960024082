import * as React from "react";

import { Content } from "../components/Content";
import { Header } from "../components/Header";
import { Hero } from "../components/Hero";
import { Theme } from "../components/Theme";
import { Typography } from "@material-ui/core";

const IndexPage = () => {
  return (
    <Theme>
      <main>
        <title>Thenable</title>
        <Header />
        <Hero />
        <Content>
          <Typography variant="h4">About Thenable</Typography>
        </Content>
      </main>
    </Theme>
  );
};

export default IndexPage;
